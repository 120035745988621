<template>
    <div class="open-cart-wrap">
        <div class="wrap-content-cart">
            <div class="logo-and-cart">   
                <div class="cart-title"><p>סל קניות</p></div>
                <div class="left-cart-content">
                    <div class="added-product shipping-cost">
                        <a href="#" data-toggle="modal" data-target="#edit-product">
                            <div class="added-product-details">
                                <div class="added-product-content">
                                    <span>עלות משלוח :</span>
                                </div>
                                <div class="added-product-price">
                                    <div class="newprice" > 
                                        <span class="minicart_price">456.00</span> ₪ </div> 
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="added-product d-none">
                        <a href="#" title="" data-toggle="modal" data-target="#edit-product">
                            <div class="added-product-details">
                                <div class="added-product-content">
                                    <p class="mb-0">עגבנייה אדומה יפה וטעימה לאכילה</p>
                                    <span><i>5</i>יחידה</span>
                                </div>
                                <div class="added-product-price">
                                    <div class="newprice" > 
                                        <span class="minicart_price">1.45</span> ₪ </div>                         
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="check-in">
                <div class="bill-details">
                    <div><span>סה"כ חייב במע"מ :</span><span><i>₪</i>500.00</span></div>
                    <div><span>סה"כ לא חייב במע"מ :</span><span><i>₪</i>400.00</span></div>
                </div>
                <a href="#" class="check-in-btn site-btn" title=""> שלם  5334  <i>₪</i></a>
            </div>
        </div>
    </div>
</template>

<script>

export default {
     data () {
        return {
        }
    },
    created () {
    },
    methods: {
    }
};
</script>