<template>
    <div>
        <product-mini-cart></product-mini-cart>
        <div class="main-content-wrap">
            <div class="top-lock-area order-lock-area">    
                <order-page-header></order-page-header>
                <order-info></order-info>
            </div>
            <div class="products-grid-wrap">
                <div class="row products-grid mt-3">
                    <div class="col-sm-3 product-grid">
                        <div class="product-grid-box">
                            <a href="#"  data-toggle="modal" data-target="#product-not-listed" class="product-plus"><i class="fa fa-plus"></i></a>
                        </div>
                    </div>
                    <div class="col-sm-3 product-grid">
                        <div class="product-grid-box">
                            <div class="product-comment alert-warning">
                                <p>מה זה, לעזעזאל, לורם איפסום</p>
                            </div>
                            <div class="product-img">
                                <a href="#" title="Missing" class="missing-product-btn site-btn">חסר במלאי</a>
                                <img src="img/tomatos.jpg" alt="Tomatos" class="img-fluid" />
                                <a href="#" title="Add" class="add-product">הוסף</a>
                            </div>
                            <div class="product-details">
                                <p class="pro-title">עגבנייה אדומה יפה וטעימה לאכילה</p>
                                <p class="pro-price">5<span>יחידות</span></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 product-grid missing-product">
                        <div class="product-grid-box">
                            <div class="product-comment alert-warning alert-warning">
                                <p>מה זה, לעזעזאל, לורם איפסום</p>
                            </div>
                            <div class="product-img">
                                <a href="#" title="Missing" class="missing-product-btn site-btn">חסר במלאי</a>
                                <img src="img/tomatos.jpg" alt="Tomatos" class="img-fluid" />
                                <a href="#" title="Add" class="add-product">חזר למלאי</a>
                            </div>
                            <div class="product-details">
                                <p class="pro-title">עגבנייה אדומה יפה וטעימה לאכילה</p>
                                <p class="pro-price">5<span>יחידות</span></p>
                            </div>
                        </div>
                    </div>
                </div>   
            </div> 
            <bottom-panel></bottom-panel>
            <product-grid-modal></product-grid-modal>
            
        </div>
    </div>
</template>

<script>
import ProductGridModal from './ProductGridModal'
import ProductMiniCart from './ProductMiniCart'
import OrderPageHeader from './OrderPageHeader'
import OrderInfo from './OrderInfo'
import BottomPanel from './BottomPanel'

export default {
    components:{ProductGridModal,ProductMiniCart,OrderPageHeader,OrderInfo,BottomPanel},
    computed: {
    },
     data () {
        return {
        }
    },
    methods: {
    }
};
</script>