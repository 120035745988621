<template>
    <div class="order-info">
        <div class="order-details">
            <div><strong>סוג אספקה</strong><p>משלוח</p></div>
            <div><strong>מועד מסירה</strong><p>12/8/21</p>19:00-16:00</div>
            <div><strong>יישוב</strong><p>אשקלון</p></div>
            <div><strong>כתובת למשלוח</strong><p>העצמאות 8 רמת גן</p>קומה: 1  דירה: 10<p><span>קוד כניסה: 2345</span></p></div>
            <div><strong>טלפונ/ים</strong><p>050-6345866</p>08-6725707</div>
        </div>
    </div>
</template>

<script>

export default {
     data () {
        return {
        }
    },
    created () {
    },
    methods: {
    }
};
</script>