<template>
    <div class="order-header">  
        <div class="order-edit-details">
            <p class="order-date-time"><span class="d-block">הזמנה 121212</span>ישראל ישראלי</p>
        </div>
        <div class="order-actions">
            <button type="button" class="print-sticker btn-site" data-toggle="modal" data-target="#print-label-modal"><img src="img/printer-orange.svg" hover-img="img/printer-black.svg" class="img-fluid" alt="printer" />הדפס מדבקות</button>
            <button type="button" class="print-order btn-site" data-toggle="modal" data-target="#print-order-modal"><img src="img/note-blue.svg" hover-img="img/note-black.svg" class="img-fluid" alt="Order" />הדפס הזמנה לליקוט</button>
            <button type="button" class="cancel-order"  data-toggle="modal" data-target="#cancel-order-modal"><img src="img/delete.svg"  class="img-fluid" alt="Delete" />ביטול עסקה</button>
        </div>
    </div>
</template>

<script>

export default {
     data () {
        return {
        }
    },
    created () {
    },
    methods: {
    }
};
</script>