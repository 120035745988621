<template>
    <div class="modal fade product-not-listed" id="product-not-listed" tabindex="-1" role="dialog" aria-labelledby="edit-productLabel">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="product-listing-wrap">
                        <div class="products-search-listing">
                            <div class="prod-search-wrap">
                                <form action="/" method="get" id="search-form" class="search-form"> 
                                   <input type="text" id="search" class="form-control" placeholder="חפש מוצר" />
                                   <button type="submit" id="search-btn" class="search-btn">
                                       <img src="img/search.png" alt="Search" class="img-fluid" />
                                   </button> 
                                </form>
                            </div>
                        </div>
                           <h2 class="modal-title">כמה עותקים להדפיס מהמסומנים?</h2>
                        <div class="products-list"> 
                            <div class="list-product row">
                                <div class="col-sm-3 product-grid">
                                    <div class="product-grid-box">
                                        <div class="product-comment alert-warning">
                                            <p>מה זה, לעזעזאל, לורם איפסום</p>
                                        </div>
                                        <div class="product-img">

                                            <img src="img/tomatos.jpg" alt="Tomatos" class="img-fluid">
                                            <a href="#" title="Add" class="add-product">הוסף</a>
                                        </div>
                                        <div class="product-details">
                                            <p class="pro-title">עגבנייה אדומה יפה וטעימה לאכילה</p>
                                            <p class="pro-price">5<span>יחידות</span></p>
                                        </div>
                                        <div class="add-to-cart">
                                            <div class="cart-wrap">
                                                <a class="plus" href="#" title="הוסף"><i class="fa fa-plus"></i></a>
                                                <p class="pro-unit">1</p>
                                                <span class="pro-unit-name">יחידה</span>
                                                <a class="minus" href="#" title="הפחת"><i class="fa fa-minus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3 product-grid">
                                    <div class="product-grid-box">
                                        <div class="product-comment alert-warning">
                                            <p>מה זה, לעזעזאל, לורם איפסום</p>
                                        </div>
                                        <div class="product-img">
                                            <img src="img/tomatos.jpg" alt="Tomatos" class="img-fluid">
                                            <a href="#" title="Add" class="add-product">הוסף</a>
                                        </div>
                                        <div class="product-details">
                                            <p class="pro-title">עגבנייה אדומה יפה וטעימה לאכילה</p>
                                            <p class="pro-price">5<span>יחידות</span></p>
                                        </div>
                                        <div class="add-to-cart">
                                            <div class="cart-wrap">
                                                <a class="plus" href="#" title="הוסף"><i class="fa fa-plus"></i></a>
                                                <p class="pro-unit">1</p>
                                                <span class="pro-unit-name">יחידה</span>
                                                <a class="minus" href="#" title="הפחת"><i class="fa fa-minus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3 product-grid">
                                    <div class="product-grid-box">
                                        <div class="product-comment alert-warning">
                                            <p>מה זה, לעזעזאל, לורם איפסום</p>
                                        </div>
                                        <div class="product-img">
                                            <img src="img/tomatos.jpg" alt="Tomatos" class="img-fluid">
                                            <a href="#" title="Add" class="add-product">הוסף</a>
                                        </div>
                                        <div class="product-details">
                                            <p class="pro-title">עגבנייה אדומה יפה וטעימה לאכילה</p>
                                            <p class="pro-price">5<span>יחידות</span></p>
                                        </div>
                                        <div class="add-to-cart">
                                            <div class="cart-wrap">
                                                <a class="plus" href="#" title="הוסף"><i class="fa fa-plus"></i></a>
                                                <p class="pro-unit">1</p>
                                                <span class="pro-unit-name">יחידה</span>
                                                <a class="minus" href="#" title="הפחת"><i class="fa fa-minus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3 product-grid">
                                    <div class="product-grid-box">
                                        <div class="product-comment alert-warning">
                                            <p>מה זה, לעזעזאל, לורם איפסום</p>
                                        </div>
                                        <div class="product-img">
                                            <img src="img/tomatos.jpg" alt="Tomatos" class="img-fluid">
                                            <a href="#" title="Add" class="add-product">הוסף</a>
                                        </div>
                                        <div class="product-details">
                                            <p class="pro-title">עגבנייה אדומה יפה וטעימה לאכילה</p>
                                            <p class="pro-price">5<span>יחידות</span></p>
                                        </div>
                                        <div class="add-to-cart">
                                            <div class="cart-wrap">
                                                <a class="plus" href="#" title="הוסף"><i class="fa fa-plus"></i></a>
                                                <p class="pro-unit">1</p>
                                                <span class="pro-unit-name">יחידה</span>
                                                <a class="minus" href="#" title="הפחת"><i class="fa fa-minus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3 product-grid">
                                    <div class="product-grid-box">
                                        <div class="product-comment alert-warning">
                                            <p>מה זה, לעזעזאל, לורם איפסום</p>
                                        </div>
                                        <div class="product-img">
                                            <img src="img/tomatos.jpg" alt="Tomatos" class="img-fluid">
                                            <a href="#" title="Add" class="add-product">הוסף</a>
                                        </div>
                                        <div class="product-details">
                                            <p class="pro-title">עגבנייה אדומה יפה וטעימה לאכילה</p>
                                            <p class="pro-price">5<span>יחידות</span></p>
                                        </div>
                                        <div class="add-to-cart">
                                            <div class="cart-wrap">
                                                <a class="plus" href="#" title="הוסף"><i class="fa fa-plus"></i></a>
                                                <p class="pro-unit">1</p>
                                                <span class="pro-unit-name">יחידה</span>
                                                <a class="minus" href="#" title="הפחת"><i class="fa fa-minus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3 product-grid">
                                    <div class="product-grid-box">
                                        <div class="product-comment alert-warning">
                                            <p>מה זה, לעזעזאל, לורם איפסום</p>
                                        </div>
                                        <div class="product-img">
                                            <img src="img/tomatos.jpg" alt="Tomatos" class="img-fluid">
                                            <a href="#" title="Add" class="add-product">הוסף</a>
                                        </div>
                                        <div class="product-details">
                                            <p class="pro-title">עגבנייה אדומה יפה וטעימה לאכילה</p>
                                            <p class="pro-price">5<span>יחידות</span></p>
                                        </div>
                                        <div class="add-to-cart">
                                            <div class="cart-wrap">
                                                <a class="plus" href="#" title="הוסף"><i class="fa fa-plus"></i></a>
                                                <p class="pro-unit">1</p>
                                                <span class="pro-unit-name">יחידה</span>
                                                <a class="minus" href="#" title="הפחת"><i class="fa fa-minus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3 product-grid">
                                    <div class="product-grid-box">
                                        <div class="product-comment alert-warning">
                                            <p>מה זה, לעזעזאל, לורם איפסום</p>
                                        </div>
                                        <div class="product-img">
                                            <img src="img/tomatos.jpg" alt="Tomatos" class="img-fluid">
                                            <a href="#" title="Add" class="add-product">הוסף</a>
                                        </div>
                                        <div class="product-details">
                                            <p class="pro-title">עגבנייה אדומה יפה וטעימה לאכילה</p>
                                            <p class="pro-price">5<span>יחידות</span></p>
                                        </div>
                                        <div class="add-to-cart">
                                            <div class="cart-wrap">
                                                <a class="plus" href="#" title="הוסף"><i class="fa fa-plus"></i></a>
                                                <p class="pro-unit">1</p>
                                                <span class="pro-unit-name">יחידה</span>
                                                <a class="minus" href="#" title="הפחת"><i class="fa fa-minus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3 product-grid">
                                    <div class="product-grid-box">
                                        <div class="product-comment alert-warning">
                                            <p>מה זה, לעזעזאל, לורם איפסום</p>
                                        </div>
                                        <div class="product-img">
                                            <img src="img/tomatos.jpg" alt="Tomatos" class="img-fluid">
                                            <a href="#" title="Add" class="add-product">הוסף</a>
                                        </div>
                                        <div class="product-details">
                                            <p class="pro-title">עגבנייה אדומה יפה וטעימה לאכילה</p>
                                            <p class="pro-price">5<span>יחידות</span></p>
                                        </div>
                                        <div class="add-to-cart">
                                            <div class="cart-wrap">
                                                <a class="plus" href="#" title="הוסף"><i class="fa fa-plus"></i></a>
                                                <p class="pro-unit">1</p>
                                                <span class="pro-unit-name">יחידה</span>
                                                <a class="minus" href="#" title="הפחת"><i class="fa fa-minus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3 product-grid">
                                    <div class="product-grid-box">
                                        <div class="product-comment alert-warning">
                                            <p>מה זה, לעזעזאל, לורם איפסום</p>
                                        </div>
                                        <div class="product-img">
                                            <img src="img/tomatos.jpg" alt="Tomatos" class="img-fluid">
                                            <a href="#" title="Add" class="add-product">הוסף</a>
                                        </div>
                                        <div class="product-details">
                                            <p class="pro-title">עגבנייה אדומה יפה וטעימה לאכילה</p>
                                            <p class="pro-price">5<span>יחידות</span></p>
                                        </div>
                                        <div class="add-to-cart">
                                            <div class="cart-wrap">
                                                <a class="plus" href="#" title="הוסף"><i class="fa fa-plus"></i></a>
                                                <p class="pro-unit">1</p>
                                                <span class="pro-unit-name">יחידה</span>
                                                <a class="minus" href="#" title="הפחת"><i class="fa fa-minus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3 product-grid">
                                    <div class="product-grid-box">
                                        <div class="product-comment alert-warning">
                                            <p>מה זה, לעזעזאל, לורם איפסום</p>
                                        </div>
                                        <div class="product-img">
                                            <img src="img/tomatos.jpg" alt="Tomatos" class="img-fluid">
                                            <a href="#" title="Add" class="add-product">הוסף</a>
                                        </div>
                                        <div class="product-details">
                                            <p class="pro-title">עגבנייה אדומה יפה וטעימה לאכילה</p>
                                            <p class="pro-price">5<span>יחידות</span></p>
                                        </div>
                                        <div class="add-to-cart">
                                            <div class="cart-wrap">
                                                <a class="plus" href="#" title="הוסף"><i class="fa fa-plus"></i></a>
                                                <p class="pro-unit">1</p>
                                                <span class="pro-unit-name">יחידה</span>
                                                <a class="minus" href="#" title="הפחת"><i class="fa fa-minus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
     data () {
        return {
        }
    },
    created () {
    },
    methods: {
    }
};
</script>