<template>
    <div class="stick-bottom-panel">
        <div class="bottom-panel-btns">
           <button type="button" class="pending-order">מוצרי</button>
           <button type="button" class="pending-order">מוצרי</button>
           <button type="button" class="pending-order">מוצרי</button>
        </div>
        <div class="btn-panel-wrap">
            <button type="button" class="check-discounts">מוצר כללי</button>
            <button type="button" class="refund-item-popus"  data-toggle="modal" data-target="#product-refund-modal">זיכוי פריט</button>
            <button type="button" class="general-discounts">הנחה כללית</button>
            <button type="button" class="finish">סיום</button>
        </div>
    </div>
</template>

<script>

export default {
    components:{},
     data () {
        return {
        }
    },
    created () {
    },
    methods: {
    }
};
</script>